import React from "react";
import "./App.css";

function App() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: "20px",
      }}
    >
      <div
        style={{
          fontSize: "2.5rem",
          fontWeight: "bold",
        }}
      >
        KickCloud
      </div>
      <a
        href="mailto:info@kickcloud.io"
        style={{
          fontSize: "1.2rem",
          color: "inherit",
          textDecoration: "none",
        }}
        onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
        onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
      >
        info@kickcloud.io
      </a>
    </div>
  );
}

export default App;
